import { createStore, combineReducers } from 'redux'
import { reducerModal } from "./views/tazo/context/modalReducer";
import { refreshReducer } from './views/tazo/context/refreshReducer';

const initialState = {
  sidebarShow: 'responsive'
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return {...state, ...rest }
    default:
      return state
  }
}

const combineReducer = combineReducers({
  changeState,
  reducerModal,
  refreshReducer
})

const store = createStore(combineReducer)
export default store