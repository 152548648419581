export const REFRESH_ON = "refresh_on";
export const REFRESH_OFF = "refresh_off";

const isRefreshing = false;

export const refreshReducer = (state = isRefreshing, action) => {
    if (action["type"] === REFRESH_ON) {
        return action["isRefreshing"];
    } else if (action["type"] === REFRESH_OFF) {
        return action["isRefreshing"]
    }
    return state;
}

export const action_refresh = (state) => {
    return {
        type: state ? REFRESH_ON : REFRESH_OFF,
        isRefreshing: state
    }
}