/**
 *  @constant
 *  @type {boolean}
 *  @description  debug console 출력 여부
 */
export const DEBUG_CONSOLE = true ;

// 페이지 COUNT 정보
export const PAGE = {
  COUNT_PER_PAGE  : 20,
  COUNT_PER_BLOCK : 10,
  COUNT_PER_FIVE_BLOCK : 5
};

/**
 *  @constant
 *  @type {Object}
 *  @description  HTTP 응답 에러 정보
 */
export const HTTP_STATUS_CODE = {
  SUCCESS       : 200 ,
  SERVER_ERROR  : 500 ,
};


/**
 *  @constant
 *  @type {Object}
 *  @description  에러 정보
 */
export const ERROR_CODE = {
  NO_ERROR      : 0 ,
  AUTH_ERROR    : 1 ,
  NO_TOKEN      : 2 ,
  TOKEN_EXPIRED : 3 ,
  VERIFY_FAIL   : 4 ,
  NO_USER       : 5 ,
  UNAUTHORIZED : 401 ,
};


/**
 *  @constant
 *  @type {String}
 *  @description  로그인 URL
 */
export const LOGIN_URL    = '/signin' ;

//header key값을 access_token에 담는다.
export const ACCESS_TOKEN = "AccessToken";
export const SERVICE_PK = "ServicePk";
export const SERVICE_CITY_PK = "ServiceCityPk";
export const ADMIN_PK = "AdminPk";
export const SERVICE_CITY_LIST = "ServiceCityList";
export const AUTH_GROUP   = 'UserAuthGroup';
export const AUTH_GROUP_LIST = 'authGroupList';

// 공통 코드
export const COMMON_CODE  = "CommonCode" ;

export const COMPLETE_MSG = '정상 처리 되었습니다.';
export const ALERT_TIME   = 1000 ;

/**
 *  @constant
 *  @type {Object}
 *  @description  element name 에 사용
 */

export const ELEMENT_NAME = {
  ID          : 'ID',
  PASSWORD    : 'Password',
  REPASSWORD  : 'RePassword',
  CHANGE_PW   : 'password',
  CONFIRM_PW  : 'confirmPassword'
};

/**
 *  @constant
 *  @type {Object}
 *  @description  paging name 에 사용
 */
export const PAGING_NAME = {
  PAGE_SIZE     : 'pageSize'    ,
  CURRENT_PAGE  : 'curPage'     ,
  TOTAL_COUNT   : 'totalCount'  ,
};


// 기본 페이징 객체
export const DEFAULT_PAGING = {
  pageSize    : PAGE.COUNT_PER_PAGE,
  curPage     : 1   ,
  totalCount  : 0   ,
};

/**
 *  @constant
 *  @type {Object}
 *  @description  회원 상태 코드
 *          ALL     : 전체
 *          ACTIVE  : 정상
 *          BANNED  : 퇄퇴
 *          STOP    : 정지
 */
export const MEMBER_STATE_CODE = {
  ALL     : 'All'     ,   // 전체
  ACTIVE  : 'Active'  ,   // 정상
  BANNED  : 'Banned'  ,   // 퇄퇴
  STOP    : 'Stop'    ,   // 정지
};

/**
 *  @constant
 *  @type {Array}
 *  @description  select box 용 회원 상태 정보
 *          ALL     : 전체
 *          ACTIVE  : 정상
 *          BANNED  : 퇄퇴
 *          STOP    : 정지
 */
export const MEMBER_STATE = [
  {
    value : MEMBER_STATE_CODE.ALL,
    text  : '전체',
  },
  {
    value : MEMBER_STATE_CODE.ACTIVE,
    text  : '정상',
  },
  {
    value : MEMBER_STATE_CODE.BANNED,
    text  : '탈퇴',
  },
  {
    value : MEMBER_STATE_CODE.STOP,
    text  : '정지',
  },
]


/* Manager */

export const MANAGER_STATE_CODE = {
  ACTIVE  : 'Active'  ,   // 정상
  STOP    : 'Stop'    ,   // 정지
};

export const MANAGER_STATE = [

  {
  value : MANAGER_STATE_CODE.ACTIVE,
  text : '정상',
  },
  {
    value : MANAGER_STATE_CODE.STOP,
    text : '정지'
  }
]

export const MANAGER_AUTHORITY = {
  SUPER : 'Super', // 슈퍼관리자
  MANAGER : 'Manager', // 관리자
}

export const MANAGER_POSITION = [
  {
    value : MANAGER_AUTHORITY.SUPER,
    text : '슈퍼관리자'
  },
  {
    value : MANAGER_AUTHORITY.MANAGER,
    text : '일반관리자'
  }
]

//서비스 지역
export const DEVICE_SERVICE_CITY_CODE = {
  ALL : '',
  CITY : '1'
}

export const DEVICE_SERVICE_CITY = [
  {
    value : DEVICE_SERVICE_CITY_CODE.ALL,
    text : '지역선택'
  },
  {
    value : DEVICE_SERVICE_CITY_CODE.CITY,
    text : '수원시'
  }
]

//서비스 지역 관리 상태 검색
export const STATION_STATUS = {
  ALL: '',
  YES_STATUS: 'Y',
  NO_STATUS: 'N',
}

export const SATATION_STATUS_CODE = [
  {
    value : STATION_STATUS.ALL,
    text : '스테이션 상태'
  },
  {
    value : STATION_STATUS.YES_STATUS,
    text : '운영'
  },
  {
    value : STATION_STATUS.NO_STATUS,
    text : '미운영'
  }
]

// LOCKER ID 정보 코드
export const lockerId = {
  manufacturer : {
    A : '삼천리',
    B : '알톤',
    C : '벨로스타',
    D : '리콘'
  },
  locker : {
    A : '삼미정보'
  },
  type : {
    T : '킥보드',
    A : '전기 자전거',
    Z : '자전거',
    O : '전기 스쿠터'
  },
  location : {
    A : '수원시'
  },
  production_year : {
    BX : 2020,
    BA : 2021,
    BB : 2022,
    BC : 2023,
    BD : 2024,
    BE : 2025,
    BF : 2026,
    BG : 2027,
    BH : 2028,
    BI : 2029,
    CX : 2030
  },
  quarter: {
    A : '1Q',
    B : '2Q',
    C : '3Q',
    D : '4Q'
  }
}

export const MANAGER_POWER_CODE = {
  AUT100 : 'AUT100',
  AUT200 : 'AUT200',
  AUT300 : 'AUT300',
  AUT400 : 'AUT400',
  AUT900 : 'AUT900',
  AUT101 : 'AUT101',
  AUT102 : 'AUT102',
  AUT201 : 'AUT201',
  AUT202 : 'AUT202',
  AUT203 : 'AUT203',
  AUT204 : 'AUT204',
  AUT301 : 'AUT301',
  AUT302 : 'AUT302',
  AUT303 : 'AUT303',
  AUT304 : 'AUT304',
  AUT305 : 'AUT305',
  AUT306 : 'AUT306',
  AUT307 : 'AUT307',
  AUT401 : 'AUT401',
  AUT402 : 'AUT402',
  AUT403 : 'AUT403',
  AUT404 : 'AUT404',
  AUT405 : 'AUT405',
  AUT406 : 'AUT406',
  AUT407 : 'AUT407',
  AUT408 : 'AUT408',
  AUT901 : 'AUT901',
  AUT902 : 'AUT902',
  AUT903 : 'AUT903',
  AUT904 : 'AUT904',
  AUT905 : 'AUT905',
}

export const PAYMENT_STATUS = {
  ALL: 'all',
  CANCEL: 'cancel',
  OUTSTANDING: 'outstanding',
  SUCCESS: 'success',
  CLAIM: 'claim'
}

export const PAYMENT_STATUS_LIST = [
  {
    value : PAYMENT_STATUS.ALL,
    text : '결제 상태'
  },
  {
    value : PAYMENT_STATUS.CANCEL,
    text : '결제취소'
  },
  {
    value : PAYMENT_STATUS.OUTSTANDING,
    text : '미결제'
  },
  {
    value : PAYMENT_STATUS.SUCCESS,
    text : '정상결제'
  },
  {
    value : PAYMENT_STATUS.CLAIM,
    text : '청구취소'
  },
]

// 공지사항 상태
export const NOTICE_STATUS_CODE = {
  ALL: '',
  OPEN: 'Y',
  CLOSE: 'N'
}

export const NOTICE_STATUS = [
  {
    value: NOTICE_STATUS_CODE.ALL,
    text: '상태'
  },
  {
    value: NOTICE_STATUS_CODE.OPEN,
    text: '공개'
  },
  {
    value: NOTICE_STATUS_CODE.CLOSE,
    text: '비공개'
  }
]

export const QNA_STATUS_CODE = {
  ALL: '',
  OPEN: 'ANS001',
  CLOSE: 'ANS002'
}

export const QNA_STATUS = [
  {
    value: NOTICE_STATUS_CODE.ALL,
    text: '상태'
  },
  {
    value: NOTICE_STATUS_CODE.OPEN,
    text: '답변'
  },
  {
    value: NOTICE_STATUS_CODE.CLOSE,
    text: '미답변'
  }
]


export const APP_VERSION_UPDATE_CODE = {
  ALL: '',
  SELECT: 'SELECT',
  FORCE: 'FORCE'
}

export const APP_VERSION_UPDATE = [
  {
    value: APP_VERSION_UPDATE_CODE.ALL,
    text: '업데이트 선택'
  },
  {
    value: APP_VERSION_UPDATE_CODE.SELECT,
    text: '선택'
  },
  {
    value: APP_VERSION_UPDATE_CODE.FORCE,
    text: '강제'
  }
]

// 상품관리
export const PRODUCT_TYPE_CODE = {
  ALL: '',
  POLICY: 'policy',
  TICKET: 'ticket'
}
export const PRODUCT_TYPE = [
  {
    value: PRODUCT_TYPE_CODE.ALL,
    text: '상품구분'
  },
  {
    value: PRODUCT_TYPE_CODE.POLICY,
    text: '기본료'
  },
  {
    value: PRODUCT_TYPE_CODE.TICKET,
    text: '이용권'
  }
]

export const PRODUCT_STATUS_CODE = {
  ALL: '',
  YES: 'Y',
  NO: 'N'
}

export const PRODUCT_STATUS = [
  {
    value: PRODUCT_STATUS_CODE.ALL,
    text: '상태'
  },
  {
    value: PRODUCT_STATUS_CODE.YES,
    text: '사용'
  },
  {
    value: PRODUCT_STATUS_CODE.NO,
    text: '미사용'
  }
]


// CSS 정의
export const CSS_DEFINE = {
  ROW_DETAIL : 'mx-2 my-1',
  COL_NAME_GITHUB  : 'bg-github py-2 pl-3',
  COL_VALUE_BOADER : 'border-dark py-2 pl-3',
}

/*################################################################################*/
// 대여 현황 PAGE
/*################################################################################*/
// 검색어 배열
export const RENTAL_STATE_CODE = {
  ALL : '',
  USAGE_NUM : 'usageNum',
  PHONE_NUM : 'phoneNum',
  DEVICE_NUM : 'deviceNum',
}
export const RENTAL_STATE = [
  {
    value : RENTAL_STATE_CODE.ALL,
    text  : '검색어',
  },
  {
    value : RENTAL_STATE_CODE.USAGE_NUM,
    text  : '이용번호',
  },
  {
    value : RENTAL_STATE_CODE.PHONE_NUM,
    text  : '전화번호',
  },
  {
    value : RENTAL_STATE_CODE.DEVICE_NUM,
    text  : 'QR',
  }
]

// sorting 배열
export const RENTAL_SORT_CODE = {
  USAGE: '',
  QR_CODE: 'QR',
  USING_TIME: 'MIN'
}

export const RENTAL_SORT = [
  {
    value : RENTAL_SORT_CODE.USAGE,
    text  : '이용번호 순',
  },
  {
    value : RENTAL_SORT_CODE.QR_CODE,
    text  : 'QR코드 순',
  },
  {
    value : RENTAL_SORT_CODE.USING_TIME,
    text  : '이용시간 순 ',
  }
]

// 대여현황 디바이스 상태 검색
export const RENTAL_STATUS_CODE = {
  ALL: '',
  USING: 'using',
  IOT: 'RET001',
  APP: 'RET002',
  SYSTEM: 'RET003',
  FAIL: 'RET004',
}
export const RENTAL_STATUS = [
  {
    text : '상태구분',
    value : RENTAL_STATUS_CODE.ALL
  },
  {
    text : '이용중',
    value : RENTAL_STATUS_CODE.USING
  },
  {
    text : '이용종료(앱)',
    value : RENTAL_STATUS_CODE.APP
  },
  {
    text : '이용종료(iot)',
    value : RENTAL_STATUS_CODE.IOT
  },
  {
    text : '대여실패',
    value : RENTAL_STATUS_CODE.FAIL
  },
  {
    text : '강제종료',
    value : RENTAL_STATUS_CODE.SYSTEM
  }
]

export const DEVICE_SEARCH_CODE = {
  ALL: '',
  ID: 'deviceId',
  USING: 'using',
  MEMBER: 'member'
}

export const DEVICE_SEARCH = [
  {
    value : DEVICE_SEARCH_CODE.ALL,
    text  : '검색어',
  },
  {
    value : DEVICE_SEARCH_CODE.ID,
    text  : '디바이스',
  }
]
export const USING_SEARCH = [
  {
    value : DEVICE_SEARCH_CODE.ALL,
    text  : '검색어',
  },
  {
    value : DEVICE_SEARCH_CODE.USING,
    text  : '이용번호',
  },
  {
    value : DEVICE_SEARCH_CODE.MEMBER,
    text  : '전화번호',
  }
]

export const PAYMENT_SEARCH = [
  {
    value : DEVICE_SEARCH_CODE.ALL,
    text  : '검색어',
  },
  {
    value : DEVICE_SEARCH_CODE.USING,
    text  : '이용번호',
  },
]

export const CS_DASHBOARD_SEARCH_CODE = {
  ALL: '',
  ID: 'deviceId',
  MOBILE: 'mobile',
  NICK: 'memberNick',
  VOC: 'vocNum'
}

// ID: 'deviceId',
//   MOBILE: 'mobile',
//   NICK: 'memberNick',
//   VOC: 'vocNum'
export const CS_DASHBOARD_SEARCH = [
  {
    value : CS_DASHBOARD_SEARCH_CODE.ALL,
    text  : '검색어',
  },
  {
    value : CS_DASHBOARD_SEARCH_CODE.VOC,
    text  : '이용번호',
  },
  {
    value : CS_DASHBOARD_SEARCH_CODE.ID,
    text  : '디바이스',
  },
  // {
  //   value : CS_DASHBOARD_SEARCH_CODE.MOBILE,
  //   text  : '전화번호',
  // },
  // {
  //   value : CS_DASHBOARD_SEARCH_CODE.NICK,
  //   text  : '이름',
  // }

]

export const PERIOD_CODE = {
  ALL: '',
  TODAY: 'TODAY',
  YESTERDAY: 'YESTERDAY',
  THISWEEK: 'THISWEEK',
  LASTWEEK: 'LASTWEEK',
  THISMONTH: 'THISMONTH',
  LASTMONTH: 'LASTMONTH',
  THISYEAR: 'THISYEAR',
  LASTYEAR: 'LASTYEAR'
}

export const PERIOD_SELECT = [
  {
    value : PERIOD_CODE.ALL,
    text  : '기간 지정',
  },
  {
    value : PERIOD_CODE.TODAY,
    text  : '오늘',
  },
  {
    value : PERIOD_CODE.YESTERDAY,
    text  : '어제',
  },
  {
    value : PERIOD_CODE.THISWEEK,
    text  : '이번 주',
  },
  {
    value : PERIOD_CODE.LASTWEEK,
    text  : '지난 한주',
  },
  {
    value : PERIOD_CODE.THISMONTH,
    text  : '이번 달',
  },
  {
    value : PERIOD_CODE.LASTMONTH,
    text  : '지난 한 달',
  },
  {
    value : PERIOD_CODE.THISYEAR,
    text  : '올해',
  },
  {
    value : PERIOD_CODE.LASTYEAR,
    text  : '지난 1년',
  }
]

export const PLATFORM_NOTICE_CODE = {
  ALL: '',
  TITLE: 'title'
}
export const PLATFORM_NOTICE = [
  {
    value : PLATFORM_NOTICE_CODE.ALL,
    text  : '검색어',
  },
  {
    value : PLATFORM_NOTICE_CODE.title,
    text  : '제목',
  },
]

export const SELECT_MEMBER_CODE = {
  NONE: '',
  REMOVE: 'remove',
  NEW : 'new',
  ADD : 'add'
}
export const SELECT_MEMBER = [
  {
    value : SELECT_MEMBER_CODE.NONE,
    text  : '선택',
  },
  {
    value : SELECT_MEMBER_CODE.REMOVE,
    text  : '리스트에서 제외',
  },
  {
    value : SELECT_MEMBER_CODE.NEW,
    text  : '신규 list 생성',
  },
  {
    value : SELECT_MEMBER_CODE.ADD,
    text  : '기존 list 추가',
  },
]


