export const logoNegative = ['608 134', `
  <title>coreui react pro logo</title>
<!--  <g>-->
<!--    <g style="fill:#80d0ff;">-->
<!--      <path d="M362.0177,90.1512,353.25,69.4149a.2507.2507,0,0,0-.2559-.1914H343.01a.2263.2263,0,0,0-.2559.2559V90.0233a.5657.5657,0,0,1-.64.64h-1.2163a.5652.5652,0,0,1-.64-.64V46.5028a.5655.5655,0,0,1,.64-.64H353.442a9.9792,9.9792,0,0,1,7.7437,3.2324A12.2,12.2,0,0,1,364.13,57.64a12.4389,12.4389,0,0,1-2.24,7.584,9.37,9.37,0,0,1-6.08,3.7441c-.1709.086-.2139.1915-.128.3194l8.7041,20.6084.064.2558q0,.5127-.5757.5118h-1.1523A.703.703,0,0,1,362.0177,90.1512ZM342.754,48.3593v18.496a.2259.2259,0,0,0,.2559.2559h10.3037a7.6713,7.6713,0,0,0,6.0166-2.5918,9.8807,9.8807,0,0,0,2.3037-6.8164,10.2875,10.2875,0,0,0-2.272-6.9756,7.6033,7.6033,0,0,0-6.0483-2.624H343.01A.2263.2263,0,0,0,342.754,48.3593Z"/>-->
<!--      <path d="M401.3263,48.1034H381.2945a.2262.2262,0,0,0-.2558.2559v18.496a.2259.2259,0,0,0,.2558.2559h13.8238a.5664.5664,0,0,1,.6406.64v.96a.5663.5663,0,0,1-.6406.6406H381.2945a.2263.2263,0,0,0-.2558.2559v18.56a.2258.2258,0,0,0,.2558.2558h20.0318a.5671.5671,0,0,1,.6406.6407v.96a.566.566,0,0,1-.6406.64H379.1827a.5653.5653,0,0,1-.64-.64V46.5028a.5656.5656,0,0,1,.64-.64h22.1436a.5664.5664,0,0,1,.6406.64v.96A.5663.5663,0,0,1,401.3263,48.1034Z"/>-->
<!--      <path d="M439.047,90.1512l-2.4317-8.832a.2971.2971,0,0,0-.32-.1924H419.5274a.2957.2957,0,0,0-.32.1924l-2.3681,8.7676a.6577.6577,0,0,1-.7036.5762H414.919a.5385.5385,0,0,1-.5756-.7041l12.0317-43.584a.6436.6436,0,0,1,.7041-.5117h1.6a.6442.6442,0,0,1,.7041.5117l12.16,43.584.0644.1923q0,.5127-.64.5118h-1.2163A.6428.6428,0,0,1,439.047,90.1512ZM419.9435,78.9188a.3031.3031,0,0,0,.2236.0967h15.4883a.3048.3048,0,0,0,.2236-.0967c.0645-.0635.0742-.1162.0322-.1592l-7.872-28.9287c-.043-.0849-.086-.1279-.128-.1279s-.0859.043-.1279.1279L419.9112,78.76C419.8683,78.8026,419.879,78.8553,419.9435,78.9188Z"/>-->
<!--      <path d="M456.6017,87.911a11.6372,11.6372,0,0,1-3.3277-8.7041V57.1913a11.4158,11.4158,0,0,1,3.36-8.5762,12.0941,12.0941,0,0,1,8.8-3.2637,12.2566,12.2566,0,0,1,8.8643,3.2315,11.3927,11.3927,0,0,1,3.36,8.6084v.64a.5663.5663,0,0,1-.6406.6407l-1.28.0634q-.6408,0-.64-.5761v-.8321a9.289,9.289,0,0,0-2.6558-6.9121,10.6734,10.6734,0,0,0-14.0161,0,9.2854,9.2854,0,0,0-2.6563,6.9121V79.3993a9.2808,9.2808,0,0,0,2.6563,6.9121,10.67,10.67,0,0,0,14.0161,0,9.2843,9.2843,0,0,0,2.6558-6.9121v-.7686q0-.5757.64-.5752l1.28.0635a.5667.5667,0,0,1,.6406.6406v.5118a11.4952,11.4952,0,0,1-3.36,8.64,13.6227,13.6227,0,0,1-17.6963,0Z"/>-->
<!--      <path d="M514.4376,46.5028v.96a.5658.5658,0,0,1-.64.6406H503.046a.2263.2263,0,0,0-.2559.2559v41.664a.566.566,0,0,1-.6406.64h-1.2158a.5652.5652,0,0,1-.64-.64V48.3593a.2266.2266,0,0,0-.2558-.2559H489.8619a.5656.5656,0,0,1-.64-.6406v-.96a.5656.5656,0,0,1,.64-.64H513.798A.5658.5658,0,0,1,514.4376,46.5028Z"/>-->
<!--      <path d="M522.0665,89.5116a2.8385,2.8385,0,0,1-.8-2.0488,2.9194,2.9194,0,0,1,.8-2.1114,2.7544,2.7544,0,0,1,2.08-.832,2.8465,2.8465,0,0,1,2.9438,2.9434,2.7541,2.7541,0,0,1-.832,2.08,2.9221,2.9221,0,0,1-2.1118.8008A2.754,2.754,0,0,1,522.0665,89.5116Z"/>-->
<!--      <path d="M542.4054,88.0077a11.3123,11.3123,0,0,1-3.2-8.416v-5.44a.5656.5656,0,0,1,.64-.64h1.2158a.5661.5661,0,0,1,.64.64v5.5039a9.1424,9.1424,0,0,0,2.5283,6.72,8.9745,8.9745,0,0,0,6.6875,2.5605,8.7908,8.7908,0,0,0,9.28-9.28V46.5028a.5655.5655,0,0,1,.64-.64h1.2163a.566.566,0,0,1,.64.64V79.5917a11.2545,11.2545,0,0,1-3.2325,8.416,13.0618,13.0618,0,0,1-17.0556,0Z"/>-->
<!--      <path d="M580.35,88.1034a10.4859,10.4859,0,0,1-3.36-8.1279v-1.792a.5663.5663,0,0,1,.64-.6407h1.0884a.5668.5668,0,0,1,.64.6407v1.6a8.5459,8.5459,0,0,0,2.752,6.6562,10.5353,10.5353,0,0,0,7.36,2.4961,9.8719,9.8719,0,0,0,6.9761-2.3681,8.2161,8.2161,0,0,0,2.56-6.336,8.4,8.4,0,0,0-1.12-4.416,11.3812,11.3812,0,0,0-3.3281-3.3926,71.6714,71.6714,0,0,0-6.1763-3.7119,71.0479,71.0479,0,0,1-6.24-3.84,12.1711,12.1711,0,0,1-3.4238-3.68,10.2614,10.2614,0,0,1-1.28-5.3438,9.8579,9.8579,0,0,1,3.0718-7.7441,12.0122,12.0122,0,0,1,8.32-2.752q5.6954,0,8.96,3.1036a10.8251,10.8251,0,0,1,3.2642,8.2246v1.6a.5658.5658,0,0,1-.64.64h-1.1519a.5652.5652,0,0,1-.64-.64V56.8075a8.8647,8.8647,0,0,0-2.624-6.6885,9.9933,9.9933,0,0,0-7.232-2.5273,9.37,9.37,0,0,0-6.5278,2.1435,7.8224,7.8224,0,0,0-2.3682,6.1123,7.8006,7.8006,0,0,0,1.0244,4.16,10.387,10.387,0,0,0,3.0078,3.0391,62.8714,62.8714,0,0,0,5.9522,3.4882,71.0575,71.0575,0,0,1,6.72,4.2559,13.4674,13.4674,0,0,1,3.648,3.9365,10.049,10.049,0,0,1,1.28,5.1836,10.7177,10.7177,0,0,1-3.2637,8.1924q-3.2637,3.0717-8.832,3.0723Q583.71,91.1757,580.35,88.1034Z"/>-->
<!--          <path class="st6" d="M78.2,104.9L67.4,91.6l15.8-19.4h-8.6c-3.9,0-6.5,1.2-8.8,3.9l-8.3,10.2V68.9V58.3h-8.3 c-2,0-3.5,1.6-3.5,3.6v7.1v36v9.2h8.3c2,0,3.5-1.6,3.5-3.6v-5.7v-8.3l6.7,8.3l5.7,7.1c1.2,1.5,2.7,2.1,4.2,2.1h11.6L78.2,104.9z"/>-->
<!--            <path class="st7" d="M82.8,112.4c-0.7-0.7-3.4-4.3-6.7-8.2c-4.4-5.2-9-11.2-10.1-12.6c1.7-2.1,13-16,14.4-17.7l0.6-0.7h-6.9 c-2.7,0-5.7,1.4-7.5,3.4l-9.9,12.2c0,0-0.1,0.1-0.1,0.1c-0.1,0-0.2-0.1-0.2-0.2V70v-1v-9.6h-7.3c-1.4,0-2.5,1.1-2.5,2.5v7.1v1 v34.2v8.9h7.3c1.4,0,2.5-1.1,2.5-2.5v-6.4V94c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.1,0,0.1,0.1l8.4,10.3l5.8,7.1c1,1.1,2.3,1.8,3.5,1.8 h9.1L82.8,112.4z"/>-->
<!--    </g>-->

<!--    <g style="fill:#fff;">-->
<!--      <g>-->
<!--        <path d="M99.835,36.0577l-39-22.5167a12,12,0,0,0-12,0l-39,22.5166a12.0339,12.0339,0,0,0-6,10.3924V91.4833a12.0333,12.0333,0,0,0,6,10.3923l39,22.5167a12,12,0,0,0,12,0l39-22.5167a12.0331,12.0331,0,0,0,6-10.3923V46.45A12.0334,12.0334,0,0,0,99.835,36.0577Zm-2,55.4256a4,4,0,0,1-2,3.4641l-39,22.5167a4.0006,4.0006,0,0,1-4,0l-39-22.5167a4,4,0,0,1-2-3.4641V46.45a4,4,0,0,1,2-3.4642l39-22.5166a4,4,0,0,1,4,0l39,22.5166a4,4,0,0,1,2,3.4642Z"/>-->
<!--        <path d="M77.8567,82.0046h-2.866a4,4,0,0,0-1.9247.4934L55.7852,91.9833,35.835,80.4648V57.4872l19.95-11.5185,17.2893,9.4549a3.9993,3.9993,0,0,0,1.9192.4906h2.8632a2,2,0,0,0,2-2V51.2024a2,2,0,0,0-1.04-1.7547L59.628,38.9521a8.0391,8.0391,0,0,0-7.8428.09L31.8346,50.56a8.0246,8.0246,0,0,0-4,6.9287v22.976a8,8,0,0,0,4,6.9283l19.95,11.5186a8.0429,8.0429,0,0,0,7.8433.0879l19.19-10.5312a2,2,0,0,0,1.0378-1.7533v-2.71A2,2,0,0,0,77.8567,82.0046Z"/>-->
<!--      </g>-->
<!--      <g>-->
<!--        <path d="M172.58,45.3618a15.0166,15.0166,0,0,0-15,14.9995V77.6387a15,15,0,0,0,30,0V60.3613A15.0166,15.0166,0,0,0,172.58,45.3618Zm7,32.2769a7,7,0,0,1-14,0V60.3613a7,7,0,0,1,14,0Z"/>-->
<!--        <path d="M135.9138,53.4211a7.01,7.01,0,0,1,7.8681,6.0752.9894.9894,0,0,0,.9843.865h6.03a1.0108,1.0108,0,0,0,.9987-1.0971,15.0182,15.0182,0,0,0-15.7162-13.8837,15.2881,15.2881,0,0,0-14.2441,15.4163V77.2037A15.288,15.288,0,0,0,136.0792,92.62a15.0183,15.0183,0,0,0,15.7162-13.8842,1.0107,1.0107,0,0,0-.9987-1.0971h-6.03a.9894.9894,0,0,0-.9843.865,7.01,7.01,0,0,1-7.8679,6.0757,7.1642,7.1642,0,0,1-6.0789-7.1849V60.6057A7.1638,7.1638,0,0,1,135.9138,53.4211Z"/>-->
<!--        <path d="M218.7572,72.9277a12.1585,12.1585,0,0,0,7.1843-11.0771V58.1494A12.1494,12.1494,0,0,0,213.7921,46H196.835a1,1,0,0,0-1,1V91a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V74h6.6216l7.9154,17.4138a1,1,0,0,0,.91.5862h6.5911a1,1,0,0,0,.91-1.4138Zm-.8157-11.0771A4.1538,4.1538,0,0,1,213.7926,66h-9.8511V54h9.8511a4.1538,4.1538,0,0,1,4.1489,4.1494Z"/>-->
<!--        <path d="M260.835,46h-26a1,1,0,0,0-1,1V91a1,1,0,0,0,1,1h26a1,1,0,0,0,1-1V85a1,1,0,0,0-1-1h-19V72h13a1,1,0,0,0,1-1V65a1,1,0,0,0-1-1h-13V54h19a1,1,0,0,0,1-1V47A1,1,0,0,0,260.835,46Z"/>-->
<!--        <path d="M298.835,46h-6a1,1,0,0,0-1,1V69.6475a7.0066,7.0066,0,1,1-14,0V47a1,1,0,0,0-1-1h-6a1,1,0,0,0-1,1V69.6475a15.0031,15.0031,0,1,0,30,0V47A1,1,0,0,0,298.835,46Z"/>-->
<!--        <rect x="307.835" y="46" width="8" height="38" rx="1"/>-->
<!--      </g>-->
<!--    </g>-->
<!--  </g>-->
<svg version="1.1" id="레이어_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
 y="0px" viewBox="0 0 522.3 180.1" style="enable-background:new 0 0 522.3 180.1;" xml:space="preserve">
<style type="text/css">
.st0{fill:url(#SVGID_1_);}
.st1{fill:url(#SVGID_2_);}
.st2{fill:url(#SVGID_3_);}
.st3{fill:url(#SVGID_4_);}
.st4{fill:url(#SVGID_5_);}
.st5{fill:url(#SVGID_6_);}
.st6{fill:url(#SVGID_7_);}
.st7{fill:url(#SVGID_8_);}
.st8{fill:url(#SVGID_9_);}
.st9{fill:url(#SVGID_10_);}
.st10{opacity:0.5;fill:url(#SVGID_11_);}
.st11{opacity:0.25;fill:url(#SVGID_12_);}
.st12{opacity:0.25;fill:url(#SVGID_13_);}
.st13{opacity:0.25;fill:url(#SVGID_14_);}
.st14{opacity:0.5;fill:url(#SVGID_15_);}
.st15{opacity:0.25;fill:url(#SVGID_16_);}
.st16{fill:url(#SVGID_17_);}
.st17{opacity:0.25;fill:url(#SVGID_18_);}
.st18{fill:url(#SVGID_19_);}
.st19{opacity:0.25;fill:url(#SVGID_20_);}
.st20{opacity:0.25;fill:url(#SVGID_21_);}
.st21{fill:url(#SVGID_22_);}
.st22{fill:url(#SVGID_23_);}
.st23{opacity:0.7;fill:url(#SVGID_24_);}
.st24{fill:url(#SVGID_25_);}
.st25{fill:url(#SVGID_26_);}
.st26{fill:url(#SVGID_27_);}
.st27{fill:url(#SVGID_28_);}
.st28{fill:#444444;}
.st29{fill:url(#SVGID_29_);}
.st30{fill:#187C40;}
.st31{fill:url(#SVGID_30_);}
.st32{opacity:0.5;}
.st33{fill:url(#SVGID_31_);}
</style>
<g id="레이어_2_1_">
</g>
<g>
<linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="245.5615" y1="57.4027" x2="245.5615" y2="104.9821">
<stop  offset="0" style="stop-color:#333333"/>
<stop  offset="1" style="stop-color:#000000"/>
</linearGradient>
<path class="st0" d="M225.7,98.6V83.7c0-1.4,0.2-2.5,0.5-3.2c0.3-0.7,0.6-1.3,0.9-1.6c0.2-0.2,0.4-0.3,0.6-0.4
c0.3-0.1,0.5-0.2,0.8-0.2h4.6c-0.3,0.4-0.6,0.9-0.8,1.4c-0.2,0.4-0.4,1-0.5,1.7c-0.2,0.7-0.2,1.5-0.2,2.4V87h9.8v-3.4
c0-1.3,0.2-2.3,0.5-3.1c0.3-0.7,0.6-1.3,0.9-1.6c0.2-0.2,0.4-0.3,0.6-0.4c0.3-0.1,0.5-0.2,0.8-0.2h4.6c-0.3,0.5-0.6,0.9-0.8,1.4
c-0.2,0.5-0.4,1-0.5,1.8c-0.2,0.7-0.2,1.5-0.2,2.5v14.7c0,1.9-0.6,3.4-1.7,4.5c-1.1,1.1-2.6,1.6-4.5,1.6h-9.1
c-1.9,0-3.4-0.5-4.5-1.6S225.7,100.5,225.7,98.6z M231.5,92.7v4.5c0,0.7,0.1,1.1,0.2,1.4c0.2,0.2,0.6,0.4,1.3,0.4h6.8
c0.7,0,1.1-0.1,1.2-0.4s0.3-0.7,0.3-1.4v-4.5H231.5z M252.8,77.6c0-0.5,0.2-0.9,0.6-1.3c0.4-0.4,0.8-0.6,1.4-0.6h3.9v12.1h6.8v3.8
c0,0.6-0.2,1-0.6,1.4s-0.8,0.6-1.3,0.6h-4.9v19c0,0.5-0.2,0.9-0.6,1.3c-0.4,0.4-0.9,0.6-1.4,0.6h-3.9V77.6z"/>
<linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="286.2041" y1="57.4027" x2="286.2041" y2="104.9821">
<stop  offset="0" style="stop-color:#333333"/>
<stop  offset="1" style="stop-color:#000000"/>
</linearGradient>
<path class="st1" d="M268.6,92.9v-4.5c0-1.2,0.2-2.5,0.7-3.7c0.5-1.2,1.2-2.3,2.1-3.2c0.9-0.9,2-1.6,3.3-2.2
c1.3-0.6,2.7-0.9,4.3-0.9h7.6c1.9,0,3.7-0.4,5.6-1.2v4.5c0,0.3-0.1,0.5-0.2,0.8c-0.1,0.2-0.3,0.4-0.5,0.5c-0.3,0.2-0.9,0.5-1.6,0.7
c-0.7,0.2-1.8,0.3-3.1,0.3h-7.2c-0.9,0-1.7,0.1-2.4,0.4c-0.7,0.3-1.2,0.6-1.6,1.1c-0.4,0.5-0.7,1-0.9,1.5c-0.2,0.5-0.3,1-0.3,1.5
v4.3c0,1.3,0.4,2.4,1.1,3.4s2,1.4,3.6,1.4h1.8c1.6,0,2.7-0.5,3.5-1.4c0.8-0.9,1.2-2.2,1.3-3.9v-5.3c0-0.2,0-0.4-0.1-0.7
c-0.1-0.2-0.3-0.5-0.5-1h6.5v6.8c0,1.5-0.2,3-0.7,4.3c-0.5,1.3-1.1,2.5-1.9,3.5c-0.8,1-1.9,1.8-3,2.4c-1.2,0.6-2.5,0.9-3.9,0.9
h-3.8c-1.4,0-2.7-0.2-3.9-0.7c-1.2-0.5-2.2-1.2-3.1-2.1c-0.8-0.9-1.5-2-2-3.2C268.9,95.9,268.6,94.5,268.6,92.9z M297.8,77.6
c0-0.5,0.2-0.9,0.6-1.3c0.4-0.4,0.8-0.6,1.4-0.6h4.1v36.9c0,0.5-0.2,0.9-0.6,1.3c-0.4,0.4-0.9,0.6-1.4,0.6h-4V77.6z"/>
<linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="328.5044" y1="57.4027" x2="328.5044" y2="104.9821">
<stop  offset="0" style="stop-color:#333333"/>
<stop  offset="1" style="stop-color:#000000"/>
</linearGradient>
<path class="st2" d="M310.6,104.2h32.2c1.9,0,3.7-0.4,5.6-1.2v4.6c0,0.3-0.1,0.5-0.2,0.8c-0.1,0.2-0.3,0.4-0.5,0.5
c-0.3,0.2-0.9,0.5-1.6,0.7c-0.7,0.2-1.8,0.3-3.2,0.3h-34.2v-3.9c0-0.5,0.2-0.9,0.6-1.3S310.2,104.2,310.6,104.2z M335.4,101.3V94
h-18.6c-0.5,0-0.9-0.2-1.3-0.6s-0.6-0.9-0.6-1.4v-3.5h20.5v-3.3c0-0.6-0.1-1-0.3-1.2c-0.2-0.2-0.6-0.2-1.3-0.2h-17.3
c-0.5,0-0.9-0.2-1.3-0.6s-0.6-0.9-0.6-1.4v-3.5h20.8c2,0,3.4,0.5,4.4,1.5c1,1,1.5,2.4,1.5,4.3v15.4c0,0.5-0.2,0.9-0.6,1.3
c-0.4,0.4-0.9,0.6-1.4,0.6H335.4z"/>
<linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="382.6782" y1="57.4027" x2="382.6782" y2="104.9821">
<stop  offset="0" style="stop-color:#333333"/>
<stop  offset="1" style="stop-color:#000000"/>
</linearGradient>
<path class="st3" d="M364.8,93H397c1.9,0,3.7-0.4,5.6-1.2v4.2c0,0.3-0.1,0.5-0.2,0.8s-0.3,0.4-0.5,0.5c-0.3,0.2-0.9,0.5-1.6,0.7
c-0.7,0.2-1.8,0.3-3.2,0.3h-34.2v-3.5c0-0.5,0.2-0.9,0.6-1.3S364.3,93,364.8,93z M370.7,86.2h2.6v-4.8h-4.4V78
c0-0.5,0.2-0.9,0.6-1.3c0.4-0.4,0.9-0.6,1.3-0.6h19.7c1.9,0,3.7-0.4,5.6-1.2v4.1c0,0.3-0.1,0.5-0.2,0.8s-0.3,0.4-0.5,0.5
c-0.3,0.2-0.9,0.5-1.6,0.7c-0.7,0.2-1.8,0.3-3.1,0.3h-11.2v4.8h6v-1.5c0-0.6,0.2-1.1,0.5-1.5c0.4-0.4,0.9-0.7,1.7-0.7h4v3.7h4.9
v3.3c0,0.6-0.2,1-0.6,1.4c-0.4,0.4-0.9,0.6-1.4,0.6h-25.7v-3.3c0-0.5,0.2-1,0.6-1.4S370.2,86.2,370.7,86.2z M375.6,112.1v1.6h20.8
v3.4c0,0.6-0.2,1-0.6,1.4c-0.4,0.4-0.8,0.6-1.3,0.6h-20.6c-0.9,0-1.7-0.1-2.2-0.2c-0.6-0.2-1-0.4-1.3-0.8c-0.3-0.4-0.5-0.9-0.6-1.5
c-0.1-0.7-0.1-1.5-0.1-2.4v-2.3c0-0.8,0-1.5,0.1-2.1s0.2-1.1,0.5-1.5c0.3-0.4,0.7-0.7,1.2-0.9c0.5-0.2,1.3-0.3,2.3-0.3H390v-1.6
h-18.1c-0.5,0-0.9-0.2-1.3-0.6s-0.6-0.8-0.6-1.4v-3.2h22.5c0.8,0,1.6,0.3,2.3,0.8c0.7,0.5,1,1.3,1,2.3v4.2c0,1-0.1,1.8-0.2,2.4
c-0.1,0.6-0.4,1.1-0.7,1.4c-0.4,0.3-0.8,0.5-1.4,0.6c-0.6,0.1-1.3,0.2-2.2,0.2H375.6z"/>
<linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="426.0034" y1="57.4027" x2="426.0034" y2="104.9821">
<stop  offset="0" style="stop-color:#333333"/>
<stop  offset="1" style="stop-color:#000000"/>
</linearGradient>
<path class="st4" d="M415.3,93.6h5.8c1.8,0,3.7-0.4,5.6-1.1v4.3c0,0.2-0.1,0.5-0.2,0.7c-0.1,0.2-0.3,0.4-0.5,0.5
c-0.3,0.2-0.8,0.5-1.5,0.7c-0.7,0.2-1.8,0.3-3.2,0.3h-8.7c-2,0-3.2-0.4-3.8-1.3c-0.6-0.8-0.9-2.1-0.9-3.9v-3.7c0-1.9,0.3-3.3,1-4
c0.7-0.7,1.9-1.1,3.8-1.1h4.6c0.2,0,0.3,0,0.4,0c0.1,0,0.2-0.1,0.2-0.2c0-0.1,0.1-0.2,0.1-0.4s0-0.4,0-0.7v-1.4h-7.8
c-0.5,0-0.9-0.2-1.3-0.6c-0.4-0.4-0.6-0.9-0.6-1.4v-3.4h12.1c1.1,0,1.9,0.3,2.6,1c0.6,0.6,0.9,1.4,0.9,2.3v5.2c0,2-0.3,3.3-1,3.9
c-0.6,0.7-1.9,1-3.7,1h-3.9c-0.7,0-1.1,0.1-1.3,0.2s-0.2,0.5-0.2,1.1v0.6c0,0.6,0.1,1,0.2,1.1S414.6,93.6,415.3,93.6z M425.5,103.5
c0-0.4,0.1-0.8,0.3-1c0.1-0.2,0.3-0.5,0.5-0.7c0.2-0.2,0.4-0.3,0.7-0.5s0.5-0.2,0.8-0.2h5c-0.6,0.9-0.9,1.7-0.9,2.5
c0,0.7,0.3,1.6,1,2.6c0.6,1,1.5,1.9,2.6,2.9c1.1,1,2.4,1.9,3.9,2.8c1.5,0.9,3.1,1.6,4.8,2.1l-1.3,3c-0.3,0.8-0.8,1.3-1.6,1.4
c-0.7,0.1-1.4,0.1-2.1-0.2c-1.5-0.6-2.8-1.2-3.8-1.9c-1.1-0.7-2.2-1.5-3.3-2.4c-1.2-1-2.1-2.1-2.9-3.5c-0.8,1.3-1.8,2.5-2.9,3.5
c-1.1,1-2.2,1.8-3.3,2.5c-1.1,0.7-2.3,1.4-3.8,2c-0.6,0.2-1.3,0.2-2,0c-0.7-0.2-1.3-0.7-1.6-1.4l-1.4-2.8c3.8-1.3,6.7-3,8.6-5
C424.5,107.4,425.5,105.4,425.5,103.5z M427.9,78.3c0-0.5,0.2-0.9,0.6-1.3c0.4-0.4,0.8-0.6,1.4-0.6h3.9v8.8h3.5v-7.6
c0-0.5,0.2-0.9,0.6-1.3c0.4-0.4,0.8-0.6,1.4-0.6h3.8v25.4c0,0.5-0.2,0.9-0.6,1.3c-0.4,0.4-0.9,0.6-1.4,0.6h-3.8V91h-3.5v7
c0,0.5-0.2,0.9-0.6,1.3s-0.9,0.6-1.4,0.6h-3.8V78.3z"/>
<linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="468.5366" y1="57.4027" x2="468.5366" y2="104.9821">
<stop  offset="0" style="stop-color:#333333"/>
<stop  offset="1" style="stop-color:#000000"/>
</linearGradient>
<path class="st5" d="M450.6,95.1h14.3v-3.8c0-0.4,0.2-0.8,0.6-1.2s0.8-0.6,1.4-0.6h4.5v5.6h11.6c1.8,0,3.6-0.4,5.5-1.2v4.4
c0,0.3-0.1,0.5-0.2,0.8s-0.3,0.4-0.5,0.5c-0.3,0.2-0.9,0.5-1.6,0.7c-0.7,0.2-1.8,0.3-3.2,0.3h-34.2v-3.7c0-0.5,0.2-0.9,0.6-1.3
C449.8,95.3,450.2,95.1,450.6,95.1z M456.5,87.5h2.6V82h-4.4v-3.5c0-0.5,0.2-0.9,0.6-1.3c0.4-0.4,0.9-0.6,1.3-0.6h19.7
c1.9,0,3.7-0.4,5.6-1.2v4.2c0,0.3-0.1,0.5-0.2,0.8s-0.3,0.4-0.5,0.5c-0.3,0.2-0.9,0.5-1.6,0.7c-0.7,0.2-1.8,0.3-3.1,0.3h-11.2v5.5
h6V85c0-0.6,0.2-1.1,0.5-1.4c0.4-0.4,0.9-0.6,1.7-0.6h4v4.5h4.9v3.5c0,0.6-0.2,1-0.6,1.4s-0.9,0.6-1.4,0.6h-25.7v-3.5
c0-0.5,0.2-0.9,0.6-1.4S456.1,87.5,456.5,87.5z M460.2,119.1c-1.7,0-2.9-0.4-3.7-1.1c-0.7-0.7-1.1-2-1.1-3.6v-6.7
c0-1.7,0.4-2.9,1.1-3.7c0.7-0.8,2-1.1,3.7-1.1h21.2v11.4c0,1.7-0.4,2.9-1.1,3.7c-0.7,0.7-2,1.1-3.6,1.1H460.2z M461.4,112.3
c0,0.7,0.1,1,0.2,1.2c0.1,0.1,0.5,0.2,1.2,0.2H474c0.7,0,1-0.1,1.2-0.2c0.1-0.1,0.2-0.5,0.2-1.1v-4.1h-12.7c-0.7,0-1.1,0.1-1.2,0.2
c-0.1,0.1-0.2,0.5-0.2,1.2V112.3z"/>
</g>
<g>
<linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="66.2576" y1="133.7496" x2="66.2576" y2="47.9133">
<stop  offset="0" style="stop-color:#414041"/>
<stop  offset="1" style="stop-color:#252A2B"/>
</linearGradient>
<path class="st6" d="M78.9,108.8L68,95.4L83.8,76h-8.6c-3.9,0-6.5,1.2-8.8,3.9L58,90.1V72.8V62.1h-8.3c-2,0-3.5,1.6-3.5,3.6v7.1v36
v9.2h8.3c2,0,3.5-1.6,3.5-3.6v-5.7v-8.3l6.7,8.3l5.7,7.1c1.2,1.5,2.7,2.1,4.2,2.1h11.6L78.9,108.8z"/>
<linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="65.6442" y1="51.0805" x2="65.6442" y2="129.1409">
<stop  offset="0" style="stop-color:#333333"/>
<stop  offset="1" style="stop-color:#000000"/>
</linearGradient>
<path class="st7" d="M83.4,116.2c-0.7-0.7-3.4-4.3-6.7-8.2c-4.4-5.2-9-11.2-10.1-12.6c1.7-2.1,13-16,14.4-17.7l0.6-0.7h-6.9
c-2.7,0-5.7,1.4-7.5,3.4l-9.9,12.2c0,0-0.1,0.1-0.1,0.1c-0.1,0-0.2-0.1-0.2-0.2V73.8v-1v-9.6h-7.3c-1.4,0-2.5,1.1-2.5,2.5v7.1v1
V108v8.9h7.3c1.4,0,2.5-1.1,2.5-2.5V108V97.8c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.1,0,0.1,0.1l8.4,10.3l5.8,7.1c1,1.1,2.3,1.8,3.5,1.8
h9.1L83.4,116.2z"/>
<linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="101.1399" y1="118.9461" x2="101.1399" y2="61.8951">
<stop  offset="0" style="stop-color:#414041"/>
<stop  offset="1" style="stop-color:#252A2B"/>
</linearGradient>
\t<path class="st8" d="M100.2,118c-6.4,0-10.9-4.4-10.9-10.8V65.6c0-2,1.6-3.5,3.6-3.5h8.3v42.8c0,1.8,1.4,3.2,3.2,3.2h8.5v6.3
\t\tc0,2-1.6,3.6-3.6,3.6L100.2,118z"/>
\t<linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="101.1402" y1="63.127" x2="101.1402" y2="115.9594">
\t\t<stop  offset="0" style="stop-color:#333333"/>
\t\t<stop  offset="1" style="stop-color:#000000"/>
\t</linearGradient>
\t<path class="st9" d="M100.2,116.9c-5.9,0-9.8-4-9.8-9.8V65.6c0-1.4,1.1-2.5,2.5-2.5l7.3,0v41.8c0,2.3,1.9,4.2,4.2,4.2h7.4v5.3
\t\tc0,1.4-1.1,2.5-2.5,2.5H100.2z"/>
\t
\t\t<radialGradient id="SVGID_11_" cx="305.2989" cy="432.8726" r="15.5716" gradientTransform="matrix(0.9515 0 0 0.9515 -243.1303 -314.2945)" gradientUnits="userSpaceOnUse">
\t\t<stop  offset="0" style="stop-color:#FFFFFF"/>
\t\t<stop  offset="1" style="stop-color:#221E1F"/>
\t</radialGradient>
\t<rect x="47.2" y="80.2" class="st10" width="0.1" height="36.7"/>
\t
\t\t<radialGradient id="SVGID_12_" cx="314.5677" cy="452.5983" r="8.0375" gradientTransform="matrix(0.9515 0 0 0.9515 -243.1303 -314.2945)" gradientUnits="userSpaceOnUse">
\t\t<stop  offset="0" style="stop-color:#FFFFFF"/>
\t\t<stop  offset="1" style="stop-color:#221E1F"/>
\t</radialGradient>
\t<path class="st11" d="M48.1,116.9c1.2,0,6.5,0,6.5,0c1.2,0,2.4-1,2.4-2.6V98h-0.1v16.3c0,1.4-1.2,2.5-2.2,2.5c0,0-6.1,0-6.5,0
\t\tV116.9z"/>
\t
\t\t<radialGradient id="SVGID_13_" cx="351.0152" cy="397.3433" r="11.9201" gradientTransform="matrix(0.9515 0 0 0.9515 -243.1303 -314.2945)" gradientUnits="userSpaceOnUse">
\t\t<stop  offset="0" style="stop-color:#FFFFFF"/>
\t\t<stop  offset="1" style="stop-color:#221E1F"/>
\t</radialGradient>
\t<path class="st12" d="M90.6,79.9V65.5c0-1.1,1.1-2.2,2.2-2.2c0,0,7,0,7.3,0l0.1-0.1c-1,0-7.4,0-7.4,0c-1.2,0-2.4,1.2-2.4,2.4v14.5
\t\tH90.6z"/>
\t
\t\t<radialGradient id="SVGID_14_" cx="350.7223" cy="428.0346" r="11.6083" gradientTransform="matrix(0.9515 0 0 0.9515 -243.1303 -314.2945)" gradientUnits="userSpaceOnUse">
\t\t<stop  offset="0" style="stop-color:#FFFFFF"/>
\t\t<stop  offset="1" style="stop-color:#221E1F"/>
\t</radialGradient>
\t<path class="st13" d="M90.6,107.4V80h-0.2v27.3H90.6z"/>
\t
\t\t<radialGradient id="SVGID_15_" cx="364.4333" cy="444.9279" r="5.852" gradientTransform="matrix(0.9515 0 0 0.9515 -243.1303 -314.2945)" gradientUnits="userSpaceOnUse">
\t\t<stop  offset="0" style="stop-color:#FFFFFF"/>
\t\t<stop  offset="1" style="stop-color:#221E1F"/>
\t</radialGradient>
\t<path class="st14" d="M100.1,104.4c0,0.5,0,1,0.1,1.5c0.2,0.9,0.7,1.7,1.4,2.3c0.8,0.7,1.8,1.1,3.2,1.1c0,0,6.8,0,7,0l0-0.2
\t\tc-1,0-7.1,0-7.1,0c-1.7,0-2.7-0.6-3.5-1.5c-0.4-0.5-0.7-1.1-0.9-1.8c-0.1-0.5-0.1-1-0.1-1.5H100.1z"/>
\t
\t\t<radialGradient id="SVGID_16_" cx="359.2837" cy="452.7498" r="10.7719" gradientTransform="matrix(0.9515 0 0 0.9515 -243.1303 -314.2945)" gradientUnits="userSpaceOnUse">
\t\t<stop  offset="0" style="stop-color:#FFFFFF"/>
\t\t<stop  offset="1" style="stop-color:#221E1F"/>
\t</radialGradient>
\t<path class="st15" d="M109.2,116.8l-9.2,0c-5.6,0-9.5-3.8-9.5-9.4h-0.1c0,5.7,3.9,9.6,9.6,9.6h9.2V116.8z"/>
\t<linearGradient id="SVGID_17_" gradientUnits="userSpaceOnUse" x1="95.3077" y1="89.3882" x2="95.3077" y2="87.0759">
\t\t<stop  offset="0" style="stop-color:#FFFFFF"/>
\t\t<stop  offset="1" style="stop-color:#211D1E"/>
\t</linearGradient>
\t<path class="st16" d="M90.4,89.4h9.8c0-2,0-4.3,0-6.3h-9.8V89.4z"/>
\t
\t\t<radialGradient id="SVGID_18_" cx="329.1166" cy="426.1739" r="5.4878" gradientTransform="matrix(0.9515 0 0 0.9515 -243.1303 -314.2945)" gradientUnits="userSpaceOnUse">
\t\t<stop  offset="0" style="stop-color:#FFFFFF"/>
\t\t<stop  offset="1" style="stop-color:#221E1F"/>
\t</radialGradient>
\t<path class="st17" d="M66.6,95.4c0,0,13.1-16.2,14.6-18H81c-0.4,0.5-14.6,17.9-14.6,17.9L66.6,95.4z"/>
\t<linearGradient id="SVGID_19_" gradientUnits="userSpaceOnUse" x1="69.4821" y1="89.69" x2="69.4821" y2="84.0444">
\t\t<stop  offset="0" style="stop-color:#FFFFFF"/>
\t\t<stop  offset="0.5337" style="stop-color:#211D1E"/>
\t\t<stop  offset="1" style="stop-color:#FFFFFF"/>
\t</linearGradient>
\t<path class="st18" d="M74.6,77c-2.6,0-5.6,1.3-7.3,3.3l-9.9,12.2l9.2,2.9c0,0,13.3-16.4,14.8-18.2C79.7,77.2,74.6,77,74.6,77z"/>
\t
\t\t<radialGradient id="SVGID_20_" cx="333.5399" cy="453.2192" r="7.2969" gradientTransform="matrix(0.9515 0 0 0.9515 -243.1303 -314.2945)" gradientUnits="userSpaceOnUse">
\t\t<stop  offset="0" style="stop-color:#FFFFFF"/>
\t\t<stop  offset="1" style="stop-color:#221E1F"/>
\t</radialGradient>
\t<path class="st19" d="M71.6,115.3c0.9,1,2.2,1.6,3.3,1.6c0,0,5.9,0,8,0l-0.1-0.1c-0.6,0-7.9,0-7.9,0
\t\tC74,116.8,72.5,116.2,71.6,115.3L71.6,115.3z"/>
\t
\t\t<radialGradient id="SVGID_21_" cx="5947.7949" cy="4994.2681" r="8.0921" gradientTransform="matrix(-0.9515 0 0 -0.9515 5707.3159 4815.793)" gradientUnits="userSpaceOnUse">
\t\t<stop  offset="0" style="stop-color:#FFFFFF"/>
\t\t<stop  offset="1" style="stop-color:#221E1F"/>
\t</radialGradient>
\t<path class="st20" d="M56.1,63.1c-1.2,0-6.5,0-6.5,0c-1.2,0-2.4,1-2.4,2.6v16.7h0.1V65.8c0-1.4,1.2-2.5,2.2-2.5c0,0,6.1,0,6.5,0
\t\tV63.1z"/>
\t<g>
\t\t<linearGradient id="SVGID_22_" gradientUnits="userSpaceOnUse" x1="114.3104" y1="75.3723" x2="59.0628" y2="89.8334">
\t\t\t<stop  offset="0" style="stop-color:#CE1F27"/>
\t\t\t<stop  offset="0.4775" style="stop-color:#A01D20"/>
\t\t\t<stop  offset="0.5892" style="stop-color:#A31D21"/>
\t\t\t<stop  offset="0.6838" style="stop-color:#AE1E22"/>
\t\t\t<stop  offset="0.7719" style="stop-color:#BF1E25"/>
\t\t\t<stop  offset="0.8258" style="stop-color:#CE1F27"/>
\t\t\t<stop  offset="0.927" style="stop-color:#991B1E"/>
\t\t\t<stop  offset="0.9458" style="stop-color:#961B1E"/>
\t\t\t<stop  offset="0.9594" style="stop-color:#8D1A1C"/>
\t\t\t<stop  offset="0.9714" style="stop-color:#7D181A"/>
\t\t\t<stop  offset="0.9824" style="stop-color:#671517"/>
\t\t\t<stop  offset="0.9927" style="stop-color:#4A1213"/>
\t\t\t<stop  offset="1" style="stop-color:#310F0F"/>
\t\t</linearGradient>
\t\t<path class="st21" d="M105.2,77.4c-6.7,0-31.1,0-31.1,0c-4.2,0-7,1.5-9,3.9l-6,7.4c0,0,0,0,0,0c2.2-1.7,5.2-2.7,8.3-2.7h37.8
\t\t\tc4.8,0,9.4-1.5,9.4-5.2c0-3.8-0.4-5.9-0.4-5.9S111.9,77.4,105.2,77.4z"/>
\t</g>
\t<linearGradient id="SVGID_23_" gradientUnits="userSpaceOnUse" x1="74.3218" y1="70.1842" x2="103.5223" y2="94.6863">
\t\t<stop  offset="0" style="stop-color:#B71F24"/>
\t\t<stop  offset="1.685390e-02" style="stop-color:#D61F26"/>
\t\t<stop  offset="3.155557e-02" style="stop-color:#DC3030"/>
\t\t<stop  offset="6.606386e-02" style="stop-color:#E85443"/>
\t\t<stop  offset="9.423064e-02" style="stop-color:#EF6A50"/>
\t\t<stop  offset="0.1124" style="stop-color:#F27254"/>
\t\t<stop  offset="0.2784" style="stop-color:#EF4F40"/>
\t\t<stop  offset="0.4775" style="stop-color:#EC2126"/>
\t\t<stop  offset="0.7079" style="stop-color:#EC2126"/>
\t\t<stop  offset="1" style="stop-color:#D61F26"/>
\t</linearGradient>
\t<path class="st22" d="M59.2,88.6l7-8.6c1.7-2.1,4-4.1,8.7-4.1h30.3c6.4,0,8.6-2.3,8.7-2.3l0.7-0.8l0,7.9c0,0,0.1,1.1-0.9,2.3
\t\tc-1.5,1.6-4.3,2.4-8.4,2.4H67.4C62.5,85.5,59.2,88.6,59.2,88.6z"/>
\t
\t\t<radialGradient id="SVGID_24_" cx="319.8343" cy="421.1903" r="55.6356" gradientTransform="matrix(0.9515 0 0 0.9515 -243.1303 -314.2945)" gradientUnits="userSpaceOnUse">
\t\t<stop  offset="0" style="stop-color:#000000"/>
\t\t<stop  offset="0.236" style="stop-color:#FFFFFF"/>
\t\t<stop  offset="0.7584" style="stop-color:#FFFFFF"/>
\t\t<stop  offset="0.7839" style="stop-color:#FAFAFA"/>
\t\t<stop  offset="0.8138" style="stop-color:#ECECEC"/>
\t\t<stop  offset="0.8459" style="stop-color:#D4D4D4"/>
\t\t<stop  offset="0.8795" style="stop-color:#B3B3B3"/>
\t\t<stop  offset="0.9144" style="stop-color:#898989"/>
\t\t<stop  offset="0.9502" style="stop-color:#555555"/>
\t\t<stop  offset="0.9863" style="stop-color:#191919"/>
\t\t<stop  offset="1" style="stop-color:#000000"/>
\t</radialGradient>
\t<path class="st23" d="M114.6,81.2c0,0.8-1,4.1-9.3,4.1H67.9c-3.4,0-6.1,1-8.7,3.2l-0.1,0.1c2-1.6,4.5-3.1,8.7-3.1h37.3
\t\tC114.6,85.5,114.6,81.2,114.6,81.2L114.6,81.2z"/>
</g>
<g>
\t<linearGradient id="SVGID_25_" gradientUnits="userSpaceOnUse" x1="185.386" y1="73.0895" x2="185.386" y2="88.6429">
\t\t<stop  offset="0" style="stop-color:#333333"/>
\t\t<stop  offset="1" style="stop-color:#000000"/>
\t</linearGradient>
\t<path class="st24" d="M194.3,100.2c0.7-0.2,1.2-0.4,1.5-0.7c0.2-0.1,0.3-0.3,0.5-0.5c0.1-0.2,0.2-0.5,0.2-0.7V94
\t\tc-1.9,0.7-3.8,1.1-5.6,1.1h-9.1c-0.7,0-1.2-0.1-1.3-0.2c-0.2-0.1-0.2-0.5-0.2-1.2v-1.1c0-0.6,0.1-1,0.2-1.2
\t\tc0.1-0.2,0.6-0.2,1.3-0.2h6.8c1.8,0,3.1-0.3,3.7-1s1-2,1-3.9v-6.1c0-0.9-0.3-1.7-0.9-2.3c-0.6-0.6-1.5-1-2.6-1h-15.1v3.4
\t\tc0,0.6,0.2,1,0.6,1.4c0.4,0.4,0.8,0.6,1.3,0.6h10.7v2.2c0,0.6-0.1,1-0.3,1.1c-0.2,0.1-0.5,0.2-0.8,0.2h-7c-1.9,0-3.1,0.4-3.8,1.1
\t\tc-0.7,0.7-1,2.1-1,4v4.5c0,1.7,0.3,3,0.9,3.9c0.6,0.8,1.9,1.3,3.8,1.3h12.1C192.5,100.6,193.6,100.5,194.3,100.2z"/>
\t<linearGradient id="SVGID_26_" gradientUnits="userSpaceOnUse" x1="195.0919" y1="73.0895" x2="195.0919" y2="88.6429">
\t\t<stop  offset="0" style="stop-color:#333333"/>
\t\t<stop  offset="1" style="stop-color:#000000"/>
\t</linearGradient>
\t<path class="st25" d="M202.4,112.5h-12.7c-0.7,0-1.1-0.1-1.3-0.2c-0.1-0.1-0.2-0.5-0.2-1.3v-7.5h-4.1c-0.6,0-1,0.2-1.4,0.6
\t\tc-0.4,0.4-0.6,0.9-0.6,1.4v7.3c0,2,0.4,3.3,1.2,4.1c0.8,0.8,2.1,1.2,4.1,1.2h15.1c1.4,0,2.4-0.1,3.2-0.3c0.7-0.2,1.3-0.4,1.6-0.7
\t\tc0.2-0.1,0.3-0.3,0.5-0.5c0.1-0.2,0.2-0.5,0.2-0.7v-4.4C206.1,112.1,204.3,112.5,202.4,112.5z"/>
\t<linearGradient id="SVGID_27_" gradientUnits="userSpaceOnUse" x1="204.5647" y1="73.0895" x2="204.5647" y2="88.6429">
\t\t<stop  offset="0" style="stop-color:#333333"/>
\t\t<stop  offset="1" style="stop-color:#000000"/>
\t</linearGradient>
\t<path class="st26" d="M207,103.7c0.4-0.4,0.6-0.8,0.6-1.3v-27h-4.1c-0.5,0-1,0.2-1.4,0.6c-0.4,0.4-0.6,0.8-0.6,1.3v27h4
\t\tC206.1,104.3,206.6,104.1,207,103.7z"/>
</g>
<g>
\t<linearGradient id="SVGID_28_" gradientUnits="userSpaceOnUse" x1="151.1135" y1="81.1909" x2="151.1135" y2="97.4101">
\t\t<stop  offset="0" style="stop-color:#333333"/>
\t\t<stop  offset="1" style="stop-color:#000000"/>
\t</linearGradient>
\t<path class="st27" d="M165.4,103.3h-32.2c-0.5,0-0.9,0.2-1.3,0.6c-0.4,0.4-0.6,0.8-0.6,1.3v3.9h34.2c1.4,0,2.5-0.1,3.2-0.3
\t\tc0.7-0.2,1.3-0.4,1.6-0.7c0.2-0.1,0.3-0.3,0.5-0.5c0.1-0.2,0.2-0.5,0.2-0.8v-4.6C169.1,102.9,167.3,103.3,165.4,103.3z"/>
\t<path class="st28" d="M156.4,83.3c0.7,0,1.1,0.1,1.3,0.3c0.2,0.2,0.3,0.6,0.3,1.3v0V99h4c0.5,0,1-0.2,1.4-0.6
\t\tc0.4-0.4,0.6-0.8,0.6-1.3v-6.7v-6.8c0-0.2,0-0.4,0-0.6c-0.6-1.4-1.6-2.9-3.3-4.4c0,0-0.4-0.4-1.1-0.7c-0.4-0.1-0.9-0.1-1.4-0.1
\t\th-19.4c0,1.2,0.3,3.6,2,5.6H156.4z"/>
\t<linearGradient id="SVGID_29_" gradientUnits="userSpaceOnUse" x1="151.3019" y1="81.1909" x2="151.3019" y2="97.4101">
\t\t<stop  offset="0" style="stop-color:#333333"/>
\t\t<stop  offset="1" style="stop-color:#000000"/>
\t</linearGradient>
\t<path class="st29" d="M156.4,83.3c0.7,0,1.1,0.1,1.3,0.3c0.2,0.2,0.3,0.6,0.3,1.3v0V99h4c0.5,0,1-0.2,1.4-0.6
\t\tc0.4-0.4,0.6-0.8,0.6-1.3v-6.7v-6.8c0-0.2,0-0.4,0-0.6c-0.6-1.4-1.6-2.9-3.3-4.4c0,0-0.4-0.4-1.1-0.7c-0.4-0.1-0.9-0.1-1.4-0.1
\t\th-19.4c0,1.2,0.3,3.6,2,5.6H156.4z"/>
</g>
<path class="st30" d="M155.5,77.4c-0.4,0-0.8,0-1.3,0.1c-0.1,0-0.1,0-0.2,0c0,0-12.1,2-18.5-0.7c0,0,0,0.3,0,0.7
\tc0,1.1-0.3,3.5,1.3,5.4c1.4,1.5,4.4,2,9.6,2.1c2.5,0.1,5.4,0.1,8.9,0.1c0,0,1.6,0.1,3.1,0c0.8,0,1.6,0.1,2.4,0.4
\tc1.3,0.5,2.2,1.3,2.8,2.7c0.2,0.5,0.4,0.9,0.4,1.6c0.5-1.1,0.7-1.7,0.8-3.1c0,0,0-0.1,0-0.1C164.8,81.5,160.6,77.4,155.5,77.4z"/>
<linearGradient id="SVGID_30_" gradientUnits="userSpaceOnUse" x1="135.4777" y1="83.0145" x2="164.7741" y2="83.0145">
\t<stop  offset="9.649936e-04" style="stop-color:#008067"/>
\t<stop  offset="1.685390e-02" style="stop-color:#0DB391"/>
\t<stop  offset="7.259321e-02" style="stop-color:#0CC790"/>
\t<stop  offset="0.1114" style="stop-color:#0CCF90"/>
\t<stop  offset="0.4451" style="stop-color:#01AD88"/>
\t<stop  offset="0.4775" style="stop-color:#00A987"/>
\t<stop  offset="0.5018" style="stop-color:#00A584"/>
\t<stop  offset="0.6211" style="stop-color:#009377"/>
\t<stop  offset="0.7088" style="stop-color:#008D73"/>
\t<stop  offset="1" style="stop-color:#00A182"/>
</linearGradient>
<path class="st31" d="M155.5,76.9c-0.5,0-0.9,0-1.4,0c-2.9,0.1-5.7,0.6-8.6,0.7c-3.3,0-6.9-0.1-10-1.4c0,0,0,0.3,0,0.7
\tc0,1.1-0.3,3.5,1.3,5.4c1.4,1.5,4.4,2,9.6,2.1c2.5,0.1,5.4,0.1,8.9,0.1c0,0,1.6,0.1,3.1,0c0.8,0,1.6,0.1,2.4,0.4
\tc1.3,0.5,2.4,1.6,2.8,3c0.2,0.5,0.3,1.2,0.4,1.9c0.5-1.1,0.8-2.1,0.8-3.4c0,0,0-0.3,0-0.4c0-2.7-1.2-5.3-3.2-7
\tC159.7,77.4,157.7,76.9,155.5,76.9z"/>
<g class="st32">
\t<radialGradient id="SVGID_31_" cx="149.7125" cy="83.3503" r="11.0668" gradientUnits="userSpaceOnUse">
\t\t<stop  offset="0" style="stop-color:#000000"/>
\t\t<stop  offset="0.236" style="stop-color:#FFFFFF"/>
\t\t<stop  offset="0.7584" style="stop-color:#FFFFFF"/>
\t\t<stop  offset="0.7839" style="stop-color:#FAFAFA"/>
\t\t<stop  offset="0.8138" style="stop-color:#ECECEC"/>
\t\t<stop  offset="0.8459" style="stop-color:#D4D4D4"/>
\t\t<stop  offset="0.8795" style="stop-color:#B3B3B3"/>
\t\t<stop  offset="0.9144" style="stop-color:#898989"/>
\t\t<stop  offset="0.9502" style="stop-color:#555555"/>
\t\t<stop  offset="0.9863" style="stop-color:#191919"/>
\t\t<stop  offset="1" style="stop-color:#000000"/>
\t</radialGradient>
\t<path class="st33" d="M135.5,76.9c0,1.5,0,3.1,0.7,4.4c0.4,0.7,0.8,1.3,1.5,1.6c0.6,0.4,1.4,0.6,2.1,0.8c1.5,0.3,3,0.4,4.5,0.5
\t\tc1.5,0.1,3,0.1,4.5,0.1l9.1,0.2c1.5,0,3.1,0.2,4.3,1.3c0.6,0.5,1,1.2,1.3,1.9c0.3,0.7,0.4,1.5,0.5,2.2c-0.1-0.8-0.3-1.5-0.6-2.2
\t\tc-0.3-0.7-0.8-1.3-1.3-1.8c-1.1-1-2.7-1.2-4.2-1.2l-9.1-0.1c-1.5,0-3,0-4.5-0.1c-1.5-0.1-3-0.2-4.5-0.6c-0.7-0.2-1.5-0.4-2.1-0.8
\t\tc-0.7-0.4-1.2-1-1.5-1.7C135.4,80,135.4,78.4,135.5,76.9z"/>
</g>
</svg>
`]
