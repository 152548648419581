export const MODAL = "modal_redux";
export const MODALON = "modal_on";
export const MODALOFF = "modal_off";

const modal_redux = false;

export const reducerModal = (state = modal_redux, action) => {
    if (action.type === MODALON) {
        return action.modal_redux;
    } else if (action.type === MODALOFF) {
        return action.modal_redux;
    } 
    return state;
}

export const action_modalOnOff = (state) => {
    return {
        type: state ? MODALON : MODALOFF,
        modal_redux: state
    }
}