import React, { useState, useReducer, useEffect } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import './scss/style.scss';
import {
  LOGIN_URL,
  ACCESS_TOKEN,
  COMMON_CODE,
  SERVICE_PK,
  ADMIN_PK,
  SERVICE_CITY_LIST, SERVICE_CITY_PK, AUTH_GROUP_LIST, AUTH_GROUP
} from './views/tazo/common/Constants';
import { Context } from './views/tazo/common/Context';
import {initialState, reducer} from "./views/tazo/context/InitContext";

// user 로그인 인증 여부
export const userAuth = {

  // localStorage에 토큰이 존재하는지 확인
  isAuthenticate : (sessionStorage.getItem(ACCESS_TOKEN) !== null && sessionStorage.getItem(COMMON_CODE) !== null) ,

  // 받아온 callback 함수 실행
  async authenticate(callback){
    userAuth.isAuthenticate = true ;

    // callback 실행
    await callback();
  },

  logout(){
    userAuth.isAuthenticate = false ;
  }

};

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login     = React.lazy(() => import('./views/pages/login/Login'));
const Register  = React.lazy(() => import('./views/pages/register/Register'));
const Page404   = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500   = React.lazy(() => import('./views/pages/page500/Page500'));

//tazo login
const SignIn = React.lazy(() => import('./views/tazo/signin/SignIn'));
const ExpirationPassword = React.lazy(() => import('./views/tazo/signin/ExpirationPassword'));

/* 커스텀 리스트 관리 */
const MemberListCustomListManager = React.lazy(() => import("./views/tazo/memberControl/memberListCustomListManage/memberListCustomListManager"));
const App = ()=>{

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect( () => {
    const token = sessionStorage.getItem(ACCESS_TOKEN)
    const adminPk = sessionStorage.getItem(ADMIN_PK)
    const servicePk = sessionStorage.getItem(SERVICE_PK)
    const serviceCityList = sessionStorage.getItem(SERVICE_CITY_LIST)
    const serviceCityPk = sessionStorage.getItem(SERVICE_CITY_PK)
    const authGroupList = sessionStorage.getItem(AUTH_GROUP_LIST)
    const managerName = sessionStorage.getItem('managerName')
    const accountList = sessionStorage.getItem('accountList')
    const platform = sessionStorage.getItem('platform')
    const showMenu = sessionStorage.getItem('showMenu')
    const superAdmin = sessionStorage.getItem('superAdmin')
    const basicAdmin = sessionStorage.getItem('basicAdmin')
    const email = sessionStorage.getItem('email')
    const authGroup = sessionStorage.getItem(AUTH_GROUP)
    const serviceMenuList = JSON.parse(sessionStorage.getItem('serviceMenuList'))
    console.log(sessionStorage.serviceMenuList, "<<<<!@#!@ASDASDASDASD")
    const serviceManagerNotice = JSON.parse(sessionStorage.getItem('serviceManagerNotice'))
    if(token) {
      dispatch({
        type: 'RE_ROADING',
        platform: platform,
        servicePk: servicePk,
        serviceCityPk: serviceCityPk,
        serviceCityList: JSON.parse(serviceCityList),
        adminPk: adminPk,
        authGroupList: JSON.parse(authGroupList),
        name: managerName,
        email: email,
        accountList: JSON.parse(accountList),
        menu: JSON.parse(showMenu),
        superAdmin: superAdmin === 'Y' ? true : false,
        basicAdmin: basicAdmin === 'Y' ? true : false,
        authGroupPk: authGroup,
        serviceMenuList: serviceMenuList,
        serviceManagerNotice: serviceManagerNotice
      })
    }
  },[])

  return (
    // <div className='rootBody'>
    <Context.Provider value={{state, dispatch}}>
      <BrowserRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
              <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
              <Route exact path="/signin" name="tazo login" render={props => <SignIn {...props}/>} />
              <Route exact path="/expirationpassword" name="change password" render={props => <ExpirationPassword {...props}/>} />

              {/* 인증 정보가 있는지 여부에 따라 분기 */}
              <Route path="/" name="Home" render={props => {
                if(userAuth.isAuthenticate){
                  return <TheLayout {...props}/> ;
                }
                else{
                  return <Redirect to={{pathname: LOGIN_URL, state: {from: props.location}}} />
                }
              }}
              />
            </Switch>
          </React.Suspense>
      </BrowserRouter>
    </Context.Provider>
    // </div>
  );
}

export default App;
