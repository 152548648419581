export const initialState = {
  servicePk: '',
  serviceCityPk: '',
  serviceCityList: null,
  accountList: null,
  managerName: '',
  email: '',
  adminPk: '',
  authGroupList: null,
  authGroupPk: '',
  show: true,
  loading: false,
  platform: '',
  superAdmin: false,
  basicAdmin: false,
  menu: {
    dashboard: false,
    notice: false,
    management: false,
    setting: false,
    authGroup: false
  },
  serviceMenuList: null,
  serviceManagerNotice: null
}

export const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_SERVICE_PK':
      return {
        ...state,
        servicePk: action.servicePk
      }
    case 'SET_SERVICE_CITY_LIST':
      return {
        ...state,
        serviceCityPk: action.serviceCityPk,
        serviceCityList: action.serviceCityList
      }
    case 'CHANGE_SERVICE_CITY_PK':
      return {
        ...state,
        serviceCityPk: action.serviceCityPk,
      }
    case 'SET_AUTH_GROUP_LIST':
      return {
        ...state,
        authGroupList: action.authGroupList
      }
    case 'SELECT_AUTH_GROUP':
      return {
        ...state,
        authGroupPk: action.authGroupPk
      }
    case 'SET_ADMIN_PK':
      return {
        ...state,
        adminPk: action.adminPk,
        managerName: action.name,
        email: action.email,
      }
    case 'RE_ROADING':
      return {
        ...state,
        servicePk: action.servicePk,
        serviceCityPk: action.serviceCityPk,
        serviceCityList: action.serviceCityList,
        adminPk: action.adminPk,
        authGroupList: action.authGroupList,
        platform: action.platform,
        managerName: action.name,
        email: action.email,
        accountList: action.accountList,
        menu: action.menu,
        superAdmin: action.superAdmin,
        basicAdmin: action.basicAdmin,
        authGroupPk: action.authGroupPk,
        serviceMenuList: action.serviceMenuList,
        serviceManagerNotice: action.serviceManagerNotice
      }
    case 'SET_ACCOUNTS_LIST':
      return {
        ...state,
        accountList: action.accountList
      }
    case 'set':
      return {
        ...state,
        show: action.sidebarShow
      }
    case 'IS_LOADING':
      return {
        ...state,
        loading: action.loading
      }
    case 'IS_PLATFORM':
      return {
        ...state,
        platform: action.platform
      }
    case 'BACK_PLATFORM':
      return {
        ...state,
        platform: action.platform,
        servicePk: action.servicePk,
        serviceCityList: action.serviceCityList
      }
    case 'SET_MENU':
      return {
        ...state,
        menu: action.menu,
      }
    case 'SET_PERMISSION':
      return {
        ...state,
        superAdmin: action.superAdmin,
        basicAdmin: action.basicAdmin
      }
    case 'SET_SERVICE_MENU_LIST':
      return {
        ...state,
        serviceMenuList: action.serviceMenuList
      }
    case 'SET_SERVICE_MANAGER_NOTICE':
      return {
        ...state,
        serviceManagerNotice: action.serviceManagerNotice
      }
    default:
      return state
  }
}
